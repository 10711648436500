import React, { useRef, useState, useEffect, useCallback } from 'react';
import { collection, getDocs, query, orderBy, startAfter, limit } from 'firebase/firestore';
import { firestore } from './firebase';
import VideoItemSeeAll from './VideoItemSeeAll';
import { useNavigate, useLocation } from 'react-router-dom';

const genresList = [
  { label: "RAP", value: "hip_hop_rap" },
  { label: "COUNTRY", value: "country" },
  { label: "ROCK", value: "rock" },
  { label: "POP", value: "pop" },
  { label: "ALTERNATIVE", value: "Alternative" },
  { label: "BLUES", value: "blues" },
  { label: "R&B/SOUL", value: "rnb_soul" },
  { label: "METAL", value: "metal" },
  { label: "INDIE", value: "indie" },
  { label: "FOLK", value: "folk" },
  { label: "CLASSICAL", value: "classical" },
  { label: "ELECTRONIC/DANCE", value: "electronic_dance" },
  { label: "JAZZ", value: "jazz" },
  { label: "LATIN", value: "latin" },
  { label: "REGGAE", value: "reggae" },
  { label: "WORLD/INTERNATIONAL", value: "world_international" }
];


const VideoSeeAll = () => {
  const dataRef = useRef([]); // Use useRef for data
  const lastDocRef = useRef(null); // Use useRef for lastDoc
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { category } = location.state;

  const scrollContainerRef = useRef(null);
  const sentinelRef = useRef(null);

  const fetchCategoryData = async () => {
    if (isFetching) return;
    setIsFetching(true);
    setLoading(true);

    const genreValue = genresList.find(g => g.label === category)?.value;

    try {
      const fetchSize = 16;
      let fetchedData = [];
      if (genreValue) {
        const videosQuery = query(
          collection(firestore, `MusicGenres/${genreValue}/Videos`),
          orderBy('releaseTimestamp', 'desc'),
          limit(fetchSize)
        );

        const videosSnapshot = await getDocs(videosQuery);
        fetchedData = videosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        lastDocRef.current = videosSnapshot.docs[videosSnapshot.docs.length - 1];
      } else {
        let collectionPath = category === 'FEATURED VIDEOS' ? 'FeaturedVideos' : 'Videos';
        const collectionRef = collection(firestore, collectionPath);
        const initialQuery = query(collectionRef, orderBy('releaseTimestamp', 'desc'), limit(16));
        const snapshot = await getDocs(initialQuery);

        fetchedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];
      }

      dataRef.current = fetchedData;

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsFetching(false);
      setLoading(false);
    }
  };

  const fetchMoreData = async () => {
    if (isFetchingMore || !lastDocRef.current) return;
    setIsFetchingMore(true);

    const genreValue = genresList.find(g => g.label === category)?.value;

    try {
      const fetchSize = 16;
      let fetchedData = [];

      if (genreValue) {
        const videosQuery = query(
          collection(firestore, `MusicGenres/${genreValue}/Videos`),
          orderBy('releaseTimestamp', 'desc'),
          startAfter(lastDocRef.current),
          limit(fetchSize)
        );

        const videosSnapshot = await getDocs(videosQuery);
        fetchedData = videosSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        lastDocRef.current = videosSnapshot.docs[videosSnapshot.docs.length - 1];
      } else {
        let collectionPath = category === 'FEATURED VIDEOS' ? 'FeaturedVideos' : 'Videos';
        const collectionRef = collection(firestore, collectionPath);
        const nextQuery = query(collectionRef, orderBy('releaseTimestamp', 'desc'), startAfter(lastDocRef.current), limit(fetchSize));
        const snapshot = await getDocs(nextQuery);

        fetchedData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        lastDocRef.current = snapshot.docs[snapshot.docs.length - 1];
      }

      dataRef.current = [...dataRef.current, ...fetchedData];

    } catch (error) {
      console.error('Error fetching more data:', error);
    } finally {
      setIsFetchingMore(false);
    }
  };

  useEffect(() => {
    fetchCategoryData();

    const observer = new IntersectionObserver(handleIntersection, {
      root: scrollContainerRef.current,
      threshold: 0.75,
    });

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    return () => {
      if (sentinelRef.current) {
        observer.unobserve(sentinelRef.current);
      }
    };
  }, [category]);

  const handleIntersection = (entries) => {
    const entry = entries[0];
    if (entry.isIntersecting && !isFetchingMore && !isFetching) {
      fetchMoreData();
    }
  };

  const handlePressBegins = (video) => {
    navigate('/view-video', { state: { video } });
  };

  const renderItem = useCallback((video) => (
    <VideoItemSeeAll key={video.id} item={video} onPress={() => handlePressBegins(video)} />
  ), []);

  return (
    <div style={styles.fullScreen}>
      <div style={styles.outerContainer} ref={scrollContainerRef}>
        <div style={styles.container}>
          <h2 style={styles.title}>{category}</h2>
          {loading ? (
            <div className="loading-spinner" style={styles.spinner}></div>
          ) : (
            <div style={styles.gridContainer}>
              {dataRef.current.map(renderItem)}
            </div>
          )}
          <div ref={sentinelRef} style={{ height: '20px', backgroundColor: 'transparent' }}></div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  outerContainer: {
    position: 'relative',
    height: 'calc(100vh - 19vh)',
    zIndex: 10,
    overflowY: 'scroll',
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    padding: '0 4.2%',
    overflow: 'visible',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#fff',
    marginBottom: '20px',
    textAlign: 'center',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    paddingBottom: '19vh',
  },
  spinner: {
    display: 'block',
    margin: 'auto',
    borderColor: '#62fbfa',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderRadius: '50%',
    borderTopColor: 'transparent',
    width: '60px',
    height: '60px',
    animation: 'spin 1s linear infinite',
  },
};

export default VideoSeeAll;
